import { useEffect, useState } from 'react'

// import LogoTitle from '../../assets/images/logo-s.png';
import Loader from 'react-loaders';
import TextZone from './TextZone'
import './index.scss'
import Logo from './Logo'

const Home = (props) => {
  const { isLandscapeView } = props

  return (
    <>
      <div className="container home-page">
        <Logo isLandscapeView={isLandscapeView}/>
        <TextZone isLandscapeView={isLandscapeView}/>
      </div>
      {/* <Loader type="pacman" /> */}
    </>
  )
}


export default Home