import { useEffect, useRef } from 'react'
import gsap from 'gsap-trial'
import Octaslash from '../../../assets/images/octaslash.png'
import './index.scss'

const Logo = (props) => {
  const { isLandscapeView } = props
  const bgRef = useRef()
  const outlineLogoRef = useRef()
  const solidLogoRef = useRef()

  useEffect(() => {


    gsap
      .timeline()
      .to(bgRef.current, {
        duration: 1,
        opacity: 1,
      })
      .from(outlineLogoRef.current, {
        drawSVG: 0,
        duration: 20,
      })

    gsap.fromTo(
      solidLogoRef.current,
      {
        opacity: 0,
      },
      {
        opacity: 1,
        delay: 1,
        duration: 2,
      }
    )
  }, [])

  return (
    <div className="logo-container" ref={bgRef}
    style ={{left : isLandscapeView ? '20%' : '-30%', top : '25%' }}
    >
      <img
        className="solid-logo"
        ref={solidLogoRef}
        src={Octaslash}
        alt="JavaScript,  Developer"
        onDragStart = {(e)=>{e.preventDefault();}}
      />

    </div>
  )
}

export default Logo
