import './index.scss'
import { useState } from 'react'
import LogoS from '../../assets/images/seph_icon.png'
import NavPagesIcon from './NavPagesIcon'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faLinkedin,
  faGithub,
  faYoutube,
  faSkype,
} from '@fortawesome/free-brands-svg-icons'
import {
  faHome,
  faUser,
  faEnvelope,
  faSuitcase,
  faBars,
  faClose,
} from '@fortawesome/free-solid-svg-icons'
import { Link, NavLink } from 'react-router-dom'

const Sidebar = (props) => {
  const [showNav, setShowNav] = useState(false);

  console.log({showNav, setShowNav})
  const { isLandscapeView } = props 

  const handleShowNav = (bool) =>{
    setShowNav(bool)
  }

  return (
    <div className="nav-bar">

        <nav className={showNav? 'mobile-show' : ''}>
          <NavPagesIcon handleShowNav={handleShowNav} />
          <FontAwesomeIcon
            onClick={() => setShowNav(false)}
            icon={faClose}
            color="#ffd700"
            size="3x"
            className='close-icon' />
        </nav>
      
      
      <ul>
        <li>
          <a
            href="https://www.linkedin.com/in/phyman-dang-20026b244/"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon
              icon={faLinkedin}
              color="#4d4d4e"
              className="anchor-icon"
            />
          </a>
        </li>
        <li>
          <a
            href="https://github.com/phy94"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon
              icon={faGithub}
              color="#4d4d4e"
              className="anchor-icon"
            />
          </a>
        </li>
        <li>
          <a
            href="https://www.youtube.com/@phy_"
            rel="noreferrer"
            target="_blank"
          >
            <FontAwesomeIcon
              icon={faYoutube}
              color="#4d4d4e"
              className="anchor-icon"
            />
          </a>
        </li>
      </ul>


      {!isLandscapeView&&!showNav&& <FontAwesomeIcon 
          onClick={() => {
            setShowNav(!showNav)
            }
          }
        style={{ zIndex: 100, }}
          icon={faBars}
          color="#ffd700"
          size="3x"
          className='hamburger-icon' />}
    </div>
  )
}

export default Sidebar
