import React from "react";
import "./index.scss";

const Card = (props) => {
    return (
        <div className="card">
            <div className="card__body">
                <img src={props.img} className="card__image" alt="project preview"/>
                <h2 className="card__title">{props.title}</h2>
                <p className="card__description">{props.description}</p>
            </div>
            <span className="cardSpan">
                <a target="_blank" rel="noreferrer" href={props.link}><button className="card__btn" >
                    {props.buttonText}
                </button></a>
                <a target="_blank" rel="noreferrer" href={props.href}><button className="card__btn" >
                    View Code
                </button></a>
            </span>
        </div>
    )
}

export default Card