import React from "react";
import AnimatedLetters from "../../AnimatedLetters";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const TextZone = (props) => {
  const { isLandscapeView } = props;
  const [letterClass, setLetterClass] = useState("text-animate");
  const nameArray = ["P", "h", "y", "m", "a", "n", "", "D", "a", "n", "g"];
  const jobArray = [
    "W",
    "e",
    "b",
    " ",
    "D",
    "e",
    "v",
    "e",
    "l",
    "o",
    "p",
    "e",
    "r",
    ".",
  ];

  useEffect(() => {
    setTimeout(() => {
      setLetterClass("text-animate-hover");
    }, 4000);
  }, []);

  return (
    <>
      <div className="text-zone" style={{ left: isLandscapeView ? 100 : 20 }}>
        <h1 className="text-container">
          <AnimatedLetters
            letterClass={letterClass}
            strArray={nameArray}
            idx={5}
          />
          <br />
          <AnimatedLetters
            letterClass={letterClass}
            strArray={jobArray}
            idx={6}
          />
        </h1>
        <span className="home-buttons">
          <Link to="/contact" className="flat-button">
            CONTACT ME
          </Link>

          <Link to="/portfolio" className="flat-button">
            PORTFOLIO
          </Link>
        </span>
        {/* <span className="resumebutton">
          <Link to="/portfolio" className="flat-button">
            MY WORK
          </Link>
        </span> */}
      </div>
    </>
  );
};

export default TextZone;
