import { useEffect, useState } from 'react'
import {
  faCss3,
  faGitAlt,
  faHtml5,
  faJsSquare,
  faPython,
  faReact,
} from '@fortawesome/free-brands-svg-icons'
import AnimatedLetters from '../AnimatedLetters'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './index.scss'
import Photo from '../../assets/images/pfp.JPG'

const About = (props) => {
  const { isLandscapeView } = props

  const [letterClass, setLetterClass] = useState('text-animate')

  useEffect(() => {
    setTimeout(() => {
      setLetterClass('text-animate-hover')
    }, 3000)
  }, [])
  return (
    <>
      <div className="container about-page">
        <div className="text-zone">
          <h1>
            <AnimatedLetters
              letterClass={letterClass}
              strArray={['A', 'b', 'o', 'u', 't', ' ', 'm', 'e']}
              idx={1}
            />
          </h1>
          <p>
            I'm a web developer with a mind for creativity, specializing in JavaScript and React. I enjoy building functional, aesthetic, and engaging web pages and interfaces.
          </p>
          <br />
          <p>
            Here are some languages and tools I've worked with.
          </p>
          <br />
          <h2 className='skillset'>JavaScript  React  HTML  CSS  Python</h2>
          <br />
          <br />
          <p>Outside of programming, I enjoy spending my time training gymnastics, lifting weights, traveling, playing games, and creating content on Youtube. </p>
          <div className="home-buttons">
          </div>
        </div>
        {isLandscapeView &&
          <div className="stage-cube-cont">
            <div className="cubespinner">
              <div className="face1">
                <FontAwesomeIcon icon={faCss3} color="#DD0031" />
              </div>
              <div className="face2">
                <FontAwesomeIcon icon={faPython} color="#F06529" />
              </div>
              <div className="face3">
                <FontAwesomeIcon icon={faHtml5} color="#28A4D9" />
              </div>
              <div className="face4">
                <FontAwesomeIcon icon={faReact} color="#5ED4F4" />
              </div>
              <div className="face5">
                <FontAwesomeIcon icon={faJsSquare} color="#EFD81D" />
              </div>
              <div className="face6">
                <FontAwesomeIcon icon={faGitAlt} color="#EC4D28" />
              </div>
            </div>
            {/* <div className='pfp'> */}
            {/* <img src={Photo} alt='profile' /> */}
            {/* </div> */}
          </div>
        }
        <div className='selfPhoto' >
          <img src={Photo} alt='profile' />
        </div>
      </div>
    </>
  )
}

export default About
