import './App.scss';
import {React, useEffect, useState} from 'react';
import { Route, Routes } from 'react-router-dom';
import Layout from './components/Layout';
import Home from './components/Home';
import About from './components/About';
import Contact from './components/Contact';
import Portfolio from './components/Portfolio';


function App() {
  const [screenInformation, setScreenInformation] = useState({ screenX: window.outerWidth, screenY: window.outerHeight})
  let aspectRatio = (screenInformation.screenX / screenInformation.screenY)
  let isLandscapeView = aspectRatio > 1.0
  console.log({isLandscapeView: isLandscapeView, aspectRatio: aspectRatio})

  const onWindowResize = (e) =>{
    const screenWidth = e.target.outerWidth
    const screenHeight = e.target.outerHeight
    setScreenInformation({screenX: screenWidth, screenY: screenHeight})
  }

  useEffect(()=>{
    console.log("Component Mounted: Added Event Listener (onWindowResize)")
    console.log({window, x: window.outerWidth, y: window.outerHeight})
    window.addEventListener("resize", onWindowResize )
    
    return(()=>{
      window.removeEventListener("resize", onWindowResize)
    })
  },[])

  return (
    <>
    <Routes>
        <Route path="/" element={<Layout isLandscapeView={isLandscapeView}/>}>
          <Route index element={<Home isLandscapeView={isLandscapeView} />} />
          <Route path="/about" element={<About isLandscapeView={isLandscapeView} />} />
          <Route path="/contact" element={<Contact isLandscapeView={isLandscapeView} />} />
          <Route path="/portfolio" element={<Portfolio isLandscapeView={isLandscapeView} />} />
        </Route>
    </Routes>
    </>
  )
}

export default App;
