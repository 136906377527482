import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faLinkedin,
  faGithub,
  faYoutube,
  faSkype,
} from '@fortawesome/free-brands-svg-icons'
import {
  faHome,
  faUser,
  faEnvelope,
  faSuitcase,
  faBars,
  faClose,
} from '@fortawesome/free-solid-svg-icons'
import { Link, NavLink } from 'react-router-dom'

const NavPagesIcon = (props) => {
  const { handleShowNav } = props
  return (<>
    <NavLink
      exact="true"
      activeclassname="active"
      to="/"
      onClick={() => handleShowNav(false)}>
      <FontAwesomeIcon icon={faHome} color="#4d4d4e" />
    </NavLink>
    <NavLink
      activeclassname="active"
      className="about-link"
      to="/about"
      onClick={() => handleShowNav(false)}>
      <FontAwesomeIcon icon={faUser} color="#4d4d4e" />
    </NavLink>
    <NavLink
      activeclassname="active"
      className="portfolio-link"
      to="/portfolio"
      onClick={() => handleShowNav(false)}
    >
      <FontAwesomeIcon icon={faSuitcase} color="#4d4d4e" />
    </NavLink>
    <NavLink
      activeclassname="active"
      className="contact-link"
      to="/contact"
      onClick={() => handleShowNav(false)}
    >
      <FontAwesomeIcon icon={faEnvelope} color="#4d4d4e" />
    </NavLink>

  </>
  
  )
}

export default NavPagesIcon