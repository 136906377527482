import React from "react";
import "./index.scss";

const Card3 = (props) => {
    return (
        <div className="card3">
            <div className="card3__body">
                <img src={props.img} className="card3__image" alt="project preview"/>
                <h2 className="card3__title">{props.title}</h2>
                <p className="card3__description">{props.description}</p>
            </div>
            <span className="cardSpan3">
                <a target="_blank" rel="noreferrer" href={props.link}><button className="card3__btn" >
                    View Project
                </button></a>
                <a target="_blank" rel="noreferrer" href={props.href}><button className="card3__btn" >
                        View Code
                </button></a>
            </span>
        </div>
    )
}

export default Card3