import React, { useEffect, useState } from "react";
import AnimatedLetters from "../AnimatedLetters";
import Card from "../Card";
import Card2 from "../Card2";
import Card3 from "../Card3";
import TipCalc from '../../assets/images/tipCalc.jpg'
import Weather from '../../assets/images/weather_app_prev.png'
import MovsPrev from '../../assets/images/MovsPrev.png'
import Python from '../../assets/images/python_snake.png'
import "./index.scss";

const Portfolio = (props) => {
    const [letterClass, setLetterClass] = useState('text-animate');;

    const { isLandscapeView } = props

    useEffect(() => {
        const timer = setTimeout(() => {
            setLetterClass('text-animate-hover');
        }, 3000);

        return () => {
            clearTimeout(timer);
        }
    });

    return (
        <>
            <div className="container portfolio-page"
            >
                <h1 className="page-title">
                    <AnimatedLetters
                        letterClass={letterClass}
                        strArray={"Projects".split("")}
                        idx={1}
                    />
                </h1>
                <div className="flex">
                    {isLandscapeView ?
                        <div className="single">
                            <Card2
                                img={MovsPrev}
                                title="Streaming App"
                                description="A full stack app built with React JS, this project connects to the TMDB API to retrieve information about films and display them within the corresponding categories and criteria.
                            It uses the Youtube and MovieTrailer libraries within React to find and display movie trailers of the selected movies.
                            The project also has authentication and database components through Firebase, allowing users to create and log into accounts. Within these accounts, users are able to save to, view, and delete from a list of favorited movies. 
                            "
                                link='https://animated-marigold-53fc84.netlify.app'
                                href='https://github.com/phy94/stream-clone' />
                        </div>
                        :
                        <div>
                            <Card3
                                img={MovsPrev}
                                title="Streaming App"
                                description="A full stack app built with React JS, this project connects to the TMDB API to retrieve information about films and display them within the corresponding categories and criteria.
                                It uses the Youtube and MovieTrailer libraries within React to find and display movie trailers of the selected movies.
                                The project also has authentication and database components through Firebase, allowing users to create and log into accounts. Within these accounts, users are able to save to, view, and delete from a list of favorited movies. 
                                "
                                link='https://animated-marigold-53fc84.netlify.app'
                                href='https://github.com/phy94/stream-clone' />
                        </div>
                    }
                    <br />
                    <div className="wrapper">
                        <Card
                            img={TipCalc}
                            title="Tip Calculator"
                            description="Built with vanilla JavaScript, HTML, and CSS. A project with real world application, focusing on JavaScript and web development core principals such as custom functions and event listeners, as well as themed styling with CSS."
                            link='https://magenta-duckanoo-ff9381.netlify.app'
                            href='https://github.com/phy94/tip-calculator'
                            buttonText='View Project'
                        />
                        <Card
                            img={Weather}
                            title="Weather App"
                            description="Another showcase of JS fundamentals, this project calls to an API to retrieve weather information based on the user’s geolocation, and, using conditionally rendered icons, it then displays real-time weather conditions."
                            link='https://drive.google.com/file/d/19_ZhO6SI6YtS4H2Rxn9n5-DnFcd8r6gs/view?usp=share_link'
                            href='https://github.com/phy94/weather-app'
                            buttonText='View Demo'
                        />
                        <Card
                            img={Python}
                            title="Python Snake Game"
                            description="Coded in Python with a TKinter GUI. This project emplores object oriented programming with an emphasis on conditional functions that assess the current and determine the next state of the game."
                            link='https://drive.google.com/file/d/1FXZM_k81eooFvAuvC-JgPrHDgpHdSwg6/view?usp=share_link'
                            href='https://github.com/phy94/python-snake'
                            buttonText='View Demo'
                        />
                    </div>
                    <br />
                </div>
            </div>
        </>
    );
}

export default Portfolio;